import React, { useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";

const SendPasswordLink = () => {
  const [email, setEmail] = useState("");
  const { sendEmailLink } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      await sendEmailLink(email);
      setMessage("Password reset link sent to your email.");
    } catch (err) {
      setError("Failed to send reset link. Please try again.");
    }
  };

  return (
    <section className="forgot-password-page">
      <div className="forgot-password-page-block">
        <div className="our-container px-3">
          <div className="inner-container mx-auto">
            <div className="forgot-password-page-body">
              <div className="">
                <div className="mb-2 forgot-password-page-heading">
                  <h3 className="fs-2 fw-medium text-center text-capitalize">
                    Forgot Password
                  </h3>
                  <p className="text-center">
                    No problem! Enter your email below, and we'll send you a link to reset it.
                  </p>
                </div>
                <div className="mx-auto forgot-password-page-form">
                  <form className="pt-2 pb-2" onSubmit={handleSubmit}>
                    <div className="d-flex flex-column mb-4 forgot-password-form-group">
                      <label htmlFor="email" className="text-capitalize mb-1">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        autoComplete="off"
                        className="px-2 rounded-0"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {error && <p className="text-danger m-0">{error}</p>}
                    </div>
                    <button
                      type="submit"
                      className="border-0 fw-semibold text-capitalize forgot-password-form-button"
                    >
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SendPasswordLink;