import React, { createContext, useState, useEffect } from "react";

// Create the Product Context
export const ProductContext = createContext();

// Create the Product Provider
export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/product/search`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              pageNo: currentPage,
              pageSize: pageSize,
            }),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setProducts(data.masterData);
        setTotalPages(Math.ceil(data.totalResult / pageSize));
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, pageSize]);

  const updateCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (e) => {
    const size = parseInt(e.target.value, 10);
    setPageSize(size);
    setCurrentPage(1);
  };

  // Function to get product details by ID from the API
  const getProductDetails = async (productId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/product/search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pkProdId: productId,
            pageNo: 1,
            pageSize: 1,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data.masterData[0];
    } catch (error) {
      console.error("Fetch error:", error);
      return null;
    }
  };

  return (
    <ProductContext.Provider
      value={{
        products,
        totalPages,
        currentPage,
        updateCurrentPage,
        pageSize,
        handlePageSizeChange,
        loading,
        getProductDetails,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
