import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AuthContext } from "../../contexts/AuthContext";
import { toast } from "react-toastify";

const SignIn = () => {
  const { signIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({
    userId: "",
    password: "",
  });

  const handleUserIdChange = (e) => {
    setUserId(e.target.value);
    validateField("userId", e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validateField("password", e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields before attempting to sign up
    if (validateForm()) {
      try {
        // Perform signup using context function
        await signIn(userId, password);
        navigate("/");
      } catch (error) {
        toast.error("Sign-Up Failed:", error,{
          autoClose: 1000,
          position: 'bottom-right'
        });
        navigate("/sign-in");
      }
    }
  };

  const validateField = (fieldName, value) => {
    let errorMessage = "";

    switch (fieldName) {
      case "userId":
        errorMessage = value.trim() ? "" : "User ID is required";
        break;
      case "password":
        errorMessage =
          value.length >= 6 ? "" : "Password must be at least 6 characters";
        break;
      default:
        break;
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMessage,
    }));
  };

  const validateForm = () => {
    let isValid = true;

    // Validate User ID
    if (!userId.trim()) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        userId: "User ID is required",
      }));
      isValid = false;
    }

    // Validate Password
    if (password.length < 6) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password must be at least 6 characters",
      }));
      isValid = false;
    }

    return isValid;
  };

  return (
    <>
      <section className="sign-in-page">
        <div className="sign-in-page-block">
          <div className="our-container px-3">
            <div className="inner-container mx-auto">
              <div className="sign-in-page-body">
                <div className="">
                  <div className="mb-2 sign-in-page-heading">
                    <h3 className="fs-2 fw-medium text-center text-capitalize">
                      Log In
                    </h3>
                  </div>
                  <div className="mx-auto sign-in-page-form">
                    <form onSubmit={handleSubmit} className="pt-2 pb-2">
                      <div className="d-flex align-align-items-center flex-column mb-4 sign-in-form-group">
                        <label htmlFor="email" className="text-capitalize mb-1">
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          autoComplete="off"
                          value={userId}
                          onChange={handleUserIdChange}
                          className={`px-2 rounded-0 ${
                            formErrors.userId ? "is-invalid" : ""
                          }`}
                        />
                        {formErrors.userId && (
                          <p className="text-danger">{formErrors.userId}</p>
                        )}
                      </div>
                      <div className="d-flex align-align-items-center flex-column mb-4 sign-in-form-group">
                        <label
                          htmlFor="password"
                          className="text-capitalize mb-1"
                        >
                          Your Password
                        </label>
                        <div className="position-relative sign-in-password-group">
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            autoComplete="off"
                            value={password}
                            onChange={handlePasswordChange}
                            className={`px-2 rounded-0 ${
                              formErrors.password ? "is-invalid" : ""
                            }`}
                          />
                          <button
                            type="button"
                            className="position-absolute top-0 end-0 password-toggle-icon"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </button>
                          {formErrors.password && (
                            <p className="text-danger">{formErrors.password}</p>
                          )}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="border-0 fw-semibold text-capitalize sign-in-form-button"
                      >
                        Log In
                      </button>
                      <p className="fs-6 mb-0 mt-2 text-end sign-in-register-para">
                        New Customer {"->"}{" "}
                        <Link
                          to={"/sign-up"}
                          className="fw-bold sign-in-register-link"
                        >
                          SignUp Now
                        </Link>
                      </p>
                      <p className="fs-6 mb-0 mt-2 text-end sign-in-register-para">
                        <Link
                          to={"/send-password-link"}
                          className="fw-bold sign-in-register-link"
                        >
                          Forgot Password
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignIn;
