import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="bg-body-tertiary">
        <div className="text-center">
          ©{new Date().getFullYear()} Karv. All Rights Reserved
        </div>
      </footer>
    </>
  );
};

export default Footer;
