import React from "react";
import "./App.css";
import "./header.scss";
import "./media.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Product from "./pages/Product";
import AddProduct from "./pages/AddProduct";
import EditProduct from "./pages/EditProduct";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import SignUpNew from "./pages/SignUpNew";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./contexts/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyAccount from "./pages/MyAccount";
import SendPasswordLink from "./pages/SendPasswordLink";
import ForgotPassword from "./pages/ForgotPassword";
import Dashboard from "./pages/Dashboard";
import Orders from "./pages/Orders";
import BusinessToBusiness from "./pages/BusinessToBusiness";
import AddBusinessToBusiness from "./pages/AddBusinessToBusiness";
import EditBusinessToBusiness from "./pages/EditBusinessToBusiness";
import ProductType from "./pages/ProductType";
import PaidOrders from "./pages/PaidOrders";

function App() {
  const location = useLocation();
  const noHeaderFooterRoutes = [
    "/sign-in",
    "/sign-up",
    "/sign-up-new",
    "/send-password-link",
    "/forgot-password",
  ];

  return (
    <AuthProvider>
      {!noHeaderFooterRoutes.includes(location.pathname) && <Header />}
      <div className="main-section">
        <ToastContainer />
        <Routes>
          <Route path="/" element={<PrivateRoute element={Dashboard} />} />
          <Route path="/orders" element={<PrivateRoute element={Orders} />} />
          <Route path="/paid-orders" element={<PrivateRoute element={PaidOrders} />} />
          <Route path="/product" element={<PrivateRoute element={Product} />} />
          <Route path="/product-type" element={<PrivateRoute element={ProductType} />} />
          <Route
            path="/add-product"
            element={<PrivateRoute element={AddProduct} />}
          />
          <Route
            path="/edit-product/:pkProdId"
            element={<PrivateRoute element={EditProduct} />}
          />
          <Route
            path="/b2b"
            element={<PrivateRoute element={BusinessToBusiness} />}
          />
          <Route
            path="/add-b2b"
            element={<PrivateRoute element={AddBusinessToBusiness} />}
          />
          <Route
            path="/edit-b2b"
            element={<PrivateRoute element={EditBusinessToBusiness} />}
          />
          {/* <Route
            path="/my-account"
            element={<PrivateRoute element={MyAccount} />}
          /> */}
          <Route path={"/sign-in"} element={<SignIn />} />
          <Route path={"/sign-up"} element={<SignUp />} />
          <Route path={"/sign-up-new"} element={<SignUpNew />} />
          <Route path={"/send-password-link"} element={<SendPasswordLink />} />
          <Route path={"/forgot-password"} element={<ForgotPassword />} />
        </Routes>
      </div>
      {!noHeaderFooterRoutes.includes(location.pathname) && <Footer />}
    </AuthProvider>
  );
}

export default App;
