import React, { useState, useEffect } from "react";
import axios from "axios";
import { GrValidate } from "react-icons/gr";

const PaidOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchOrders();
  }, [currentPage, pageSize]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // fetching all order and showing in table
  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/order/getShipmentOrder`,
        {
          pkOrdID: "",
          clientName: "",
          clientEmail: "",
          clientPhNo: "",
          clientAdd: "",
          orderId: "",
          paymentId: "",
          pageSize: pageSize,
          pageNo: currentPage,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.status === "Successful") {
        setOrders(response.data.orderData);
        setTotalPages(Math.ceil(response.data.totalResult / pageSize));
        setError(null);
      } else {
        setOrders([]);
        setTotalPages(0);
        setError("No orders found");
      }
    } catch (error) {
      setError("Error fetching orders");
    } finally {
      setLoading(false);
    }
  };

  // now i hit login api in validity button in table action button
  const handleLogin = async (order) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SHIPROCKET_API_RUL}/auth/login`,
        {
          email: "mukeshkmwl@gmail.com",
          password: "Mukesh@123",
        }
      );

      if (response.data.token) {
        localStorage.setItem("shiprocketToken", response.data.token);
        await checkServiceability(order); // Call the serviceability check after login
      } else {
        console.error("Login failed, token not received.");
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  // login api successfully after calling serviceability
  const checkServiceability = async (order) => {
    const token = localStorage.getItem("shiprocketToken");
    if (!token) {
      console.error("No Shiprocket token found.");
      return;
    }

    const staticPickupPostcode = "400705"; // Static pickup postcode
    const staticWeight = 0.5; // Static weight

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SHIPROCKET_API_RUL}/courier/serviceability`,
        {
          params: {
            pickup_postcode: staticPickupPostcode,
            delivery_postcode: order.pincode,
            cod: order.isCod,
            weight: staticWeight,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Serviceability response:", response.data);

      // Call createOrder after serviceability check
      await createOrder(order);
    } catch (error) {
      console.error("Error checking serviceability:", error);
    }
  };

  // serviceability api successfully after calling create shiprocket order
  const createOrder = async (order) => {
    const token = localStorage.getItem("shiprocketToken");
    if (!token) {
      console.error("No Shiprocket token found.");
      return;
    }

    // Get the current date and time in the required format
    const orderDate = new Date().toISOString(); // Format: "2024-09-04T17:59:44Z"

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SHIPROCKET_API_RUL}/orders/create/adhoc`,
        {
          order_id: order.orderId,
          order_date: order.orderDate,
          pickup_location: "KARV ACC",
          channel_id: "",
          comment: "",
          reseller_name: "",
          company_name: "",
          billing_customer_name: order.clientName,
          billing_last_name: "",
          billing_address: order.clientAdd1,
          billing_address_2: order.clientAdd2,
          billing_isd_code: "",
          billing_city: order.city,
          billing_pincode: order.pincode,
          billing_state: order.state,
          billing_country: order.country,
          billing_email: order.clientEmail,
          billing_phone: order.clientPhNo1,
          billing_alternate_phone: order.clientPhNo2,
          shipping_is_billing: "1",
          shipping_customer_name: "",
          shipping_last_name: "",
          shipping_address: "",
          shipping_address_2: "",
          shipping_city: "",
          shipping_pincode: "",
          shipping_country: "",
          shipping_state: "",
          shipping_email: "",
          shipping_phone: "",
          order_items: order.orderDetail.map((item) => ({
            name: item.prodName,
            sku: item.prodCode,
            units: 1, // Assuming 1 unit per item
            selling_price: parseFloat(item.prodPrice),
            discount: "",
            tax: "",
            hsn: "",
          })),
          payment_method: order.isPrepaid === "1" ? "Prepaid" : "COD",
          shipping_charges: "",
          giftwrap_charges: "",
          transaction_charges: "",
          total_discount: "",
          sub_total: parseFloat(order.orderAmount),
          length: "10", // Example values, adjust as needed
          breadth: "10",
          height: "10",
          weight: "0.80",
          ewaybill_no: "",
          customer_gstin: "",
          invoice_number: "",
          order_type: "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Create order response:", response.data);

      // After order creation, assign courier
      await assignCourier(response.data.shipment_id, response.data.order_id);
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  // shiprocket order api successfully after calling assign courier awb
  const assignCourier = async (shipmentId, shipOrderId) => {
    const token = localStorage.getItem("shiprocketToken");
    if (!token) {
      console.error("No Shiprocket token found.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SHIPROCKET_API_RUL}/courier/assign/awb`,
        {
          shipment_id: shipmentId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Assign courier response:", response.data);
      // Ensure response.data.response.data contains awb_code
      const awbCode = response.data.response?.data?.awb_code;

      if (awbCode) {
        console.log("Get awb code:", awbCode);

        // After assigning the courier, generate pickup
        await generatePickup(shipmentId, shipOrderId, awbCode);
      } else {
        console.error("AWB code not found in the response.");
      }
    } catch (error) {
      console.error("Error assigning courier:", error);
    }
  };

  // awb api successfully after calling generate pickup
  const generatePickup = async (shipmentId, shipOrderId, awbCode) => {
    const token = localStorage.getItem("shiprocketToken");
    if (!token) {
      console.error("No Shiprocket token found.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SHIPROCKET_API_RUL}/courier/generate/pickup`,
        {
          shipment_id: [shipmentId],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Pickup generated:", response.data);

      // After generating pickup, generate manifest
      await generateManifest(shipmentId, shipOrderId, awbCode);
    } catch (error) {
      console.error("Error generating pickup:", error);
    }
  };

  // generate pickup api successfully after calling generate manifest
  const generateManifest = async (shipmentId, shipOrderId, awbCode) => {
    const token = localStorage.getItem("shiprocketToken");
    if (!token) {
      console.error("No Shiprocket token found.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SHIPROCKET_API_RUL}/manifests/generate`,
        {
          shipment_id: [shipmentId],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Manifest generated:", response.data);

      const manifestUrl1 = response.data.manifest_url;

      // After generating manifest, print the manifest
      await printManifest(shipOrderId, shipmentId, awbCode, manifestUrl1);
    } catch (error) {
      console.error("Error generating manifest:", error);
    }
  };

  // generate manifest api successfully after calling generate print manifest url
  const printManifest = async (
    shipOrderId,
    shipmentId,
    awbCode,
    manifestUrl1
  ) => {
    const token = localStorage.getItem("shiprocketToken");
    if (!token) {
      console.error("No Shiprocket token found.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SHIPROCKET_API_RUL}/manifests/print`,
        {
          order_ids: [shipOrderId],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Manifest printed:", response.data);
      const manifestUrl2 = response.data.manifest_url;

      // After printing manifest, generate the shipping label
      await generateLabel(
        shipOrderId,
        shipmentId,
        awbCode,
        manifestUrl1,
        manifestUrl2
      );
    } catch (error) {
      console.error("Error printing manifest:", error);
    }
  };

  // generate print manifest url api successfully after calling generate label
  const generateLabel = async (
    shipOrderId,
    shipmentIds,
    awbCode,
    manifestUrl1,
    manifestUrl2
  ) => {
    const token = localStorage.getItem("shiprocketToken");
    if (!token) {
      console.error("No Shiprocket token found.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SHIPROCKET_API_RUL}/courier/generate/label`,
        {
          shipment_id: [shipmentIds],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Shipping label generated:", response.data);
      const labelUrl = response.data.label_url;

      // After shipping label, print invoices
      await printInvoice(
        shipOrderId,
        shipmentIds,
        awbCode,
        manifestUrl1,
        manifestUrl2,
        labelUrl
      );
    } catch (error) {
      console.error("Error generating shipping label:", error);
    }
  };

  // generate label api successfully after calling print invoices
  const printInvoice = async (
    shipOrderId,
    shipmentIds,
    awbCode,
    manifestUrl1,
    manifestUrl2,
    labelUrl
  ) => {
    const token = localStorage.getItem("shiprocketToken");
    if (!token) {
      console.error("No Shiprocket token found.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SHIPROCKET_API_RUL}/orders/print/invoice`,
        {
          ids: [shipOrderId],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Shipping print invoices:", response.data);
      const invoiceUrl = response.data.invoice_url;

      // After print invoices, call awb for track order
      await trackAWB(shipOrderId, shipmentIds, awbCode);

      // call the mailing API all PDF send to this API
      await sendShiprocketDocuments(
        shipOrderId,
        shipmentIds,
        awbCode,
        manifestUrl1,
        manifestUrl2,
        labelUrl,
        invoiceUrl
      );
    } catch (error) {
      console.error("Error print invoices shipping:", error);
    }
  };

  // track AWB last api
  const trackAWB = async (shipOrderId, shipmentIds, awbCode) => {
    const token = localStorage.getItem("shiprocketToken");
    if (!token) {
      console.error("No Shiprocket token found.");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SHIPROCKET_API_RUL}/courier/track/awb/${awbCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Serviceability response:", response.data);
    } catch (error) {
      console.error("Error checking serviceability:", error);
    }
  };

  // final API for all PDFs url
  const sendShiprocketDocuments = async (
    shipOrderId,
    shipmentIds,
    awbCode,
    manifestUrl1,
    manifestUrl2,
    labelUrl,
    invoiceUrl
  ) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No Shiprocket token found.");
      return;
    }

    const data = {
      manifestUrl1,
      manifestUrl2,
      labelUrl,
      invoiceUrl,
      awbNumber: awbCode,
      orderNumber: shipOrderId,
      shipmentNumber: shipmentIds,
    };

    console.table([
      manifestUrl1,
      manifestUrl2,
      labelUrl,
      invoiceUrl,
      awbCode,
      shipOrderId,
      shipmentIds,
    ]);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/order/sentShiprocketDocuments`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Log the response data
      console.log("Response:", response.data);
    } catch (error) {
      // Log any error that occurs
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <section className="paid-order-page">
      <div className="our-container px-3">
        <div className="inner-container">
          <div className="paid-order-heading mb-4">
            <h2>Paid Orders</h2>
          </div>
          <div className="paid-order-page-body table-responsive">
            {error ? (
              <div>{error}</div>
            ) : (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Client Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Address</th>
                      <th scope="col">Order Status</th>
                      <th scope="col">Order Amount</th>
                      <th scope="col">Order ID</th>
                      <th scope="col">Payment ID</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order, index) => (
                      <tr key={order.pkorderId}>
                        <th scope="row">
                          {(currentPage - 1) * pageSize + index + 1}
                        </th>
                        <td>{order.clientName}</td>
                        <td>{order.clientEmail}</td>
                        <td>{order.clientPhNo1 || order.clientPhNo1}</td>
                        <td>{order.clientAdd1 || order.clientAdd2}</td>
                        <td>{order.orderStatus}</td>
                        <td>{order.orderAmount}</td>
                        <td>{order.orderId}</td>
                        <td>{order.paymentId || "N/A"}</td>
                        <td>
                          <button
                            className="paid-order-btn"
                            onClick={() => handleLogin(order)}
                          >
                            <GrValidate />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination-controls">
                  <label>
                    Show
                    <select
                      value={pageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                      <option value={20}>20</option>
                    </select>
                    Orders per Page
                  </label>
                  <div className="pagination-buttons">
                    <button
                      className="pagination-btn"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <span>
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      className="pagination-btn"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage >= totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaidOrders;
