import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import { AuthContext } from "../../contexts/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";

const SignUp = () => {
  const { signUp } = useContext(AuthContext);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isEmailLinkClicked, setIsEmailLinkClicked] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState(""); // Store verified email
  const [emailExists, setEmailExists] = useState(false); // Track if email already exists
  const [formErrors, setFormErrors] = useState({
    userId: "",
    password: "",
    confirmPassword: "",
  });

  const checkEmailVerifyBtn = async () => {
    try {
      // Basic email format check
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(userId.trim())) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          userId: "Invalid email format",
        }));
        return;
      }

      //  // Check if email is already verified
      //  const emailCheckResponse = await axios.post(`${process.env.REACT_APP_API_URL}/checkEmail`, { email: userId });
      //  if (emailCheckResponse.data.exists) {
      //    setEmailExists(true);
      //    setFormErrors((prevErrors) => ({
      //      ...prevErrors,
      //      userId: "Email already exists. Please use a different email.",
      //    }));
      //    toast.error("Email already exists. Please use a different email.");
      //    return;
      //  } else {
      //    setEmailExists(false);
      //  }

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/activateAccount`, { email: userId, url: `https://admin.karvaccessories.com/sign-up-new?email=${userId}`});
      if (response.data.status === "success") {
        setIsEmailVerified(true);
        setVerifiedEmail(userId);
        setIsEmailLinkClicked(true);
        toast.success("Email verified successfully!",{
          autoClose: 1000,
          position: 'bottom-right'
        });
      } else {
        setIsEmailVerified(false);
        setIsEmailLinkClicked(false);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          userId: "Email verification failed. Please use a valid email.",
        }));
        toast.error("Email verification failed",{
          autoClose: 1000,
          position: 'bottom-right'
        });
      }
    } catch (error) {
      toast.error(`Email verification failed: ${error.message}`,{
        autoClose: 1000,
        position: 'bottom-right'
      });
      setIsEmailVerified(false);
      setIsEmailLinkClicked(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleUserIdChange = (e) => {
    setUserId(e.target.value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      userId: "",
    }));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validateField("password", e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    validateField("confirmPassword", e.target.value);
  };

  const validateField = (fieldName, value) => {
    let errorMessage = "";

    switch (fieldName) {
      case "password":
        errorMessage = value.length >= 6 ? "" : "Password must be at least 6 characters";
        break;
      case "confirmPassword":
        errorMessage = value === password ? "" : "Passwords do not match";
        break;
      default:
        break;
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMessage,
    }));
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        await signUp(verifiedEmail, password); // Use verified email for sign-up
        navigate("/sign-in");
      } catch (error) {
        toast.error(`Sign-Up Failed: ${error.message}`,{
          autoClose: 1000,
          position: 'bottom-right'
        });
        navigate("/sign-up");
      }
    }
  };

  const validateForm = () => {
    let isValid = true;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userId.trim())) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        userId: "Invalid email format",
      }));
      isValid = false;
    }

    // if (emailExists) {
    //   isValid = false;
    // }

    if (!isEmailVerified) {
      toast.error("Please verify your email before signing up",{
        autoClose: 1000,
        position: 'bottom-right'
      });
      isValid = false;
    }

    if (password.length < 6) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password must be at least 6 characters",
      }));
      isValid = false;
    }

    if (confirmPassword !== password) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Passwords do not match",
      }));
      isValid = false;
    }

    return isValid;
  };

  return (
    <>
      <section className="sign-up-page">
        <div className="sign-up-page-block">
          <div className="our-container px-3">
            <div className="inner-container mx-auto">
              <div className="sign-up-page-body">
                <div className="">
                  <div className="mb-2 sign-up-page-heading">
                    <h3 className="fs-2 fw-medium text-center text-capitalize">
                      Sign Up
                    </h3>
                  </div>
                  <div className="mx-auto sign-up-page-form">
                    <form onSubmit={handleSignUp} className="pt-2 pb-2">
                      <div className="mb-4">
                        <div className="d-flex align-align-items-center flex-column sign-up-form-group">
                          <label htmlFor="email" className="text-capitalize mb-1">
                            Email
                          </label>
                          <div className="position-relative sign-up-email-group">
                            <input
                              type="email"
                              id="email"
                              autoComplete="off"
                              value={userId}
                              onChange={handleUserIdChange}
                              className={`px-2 rounded-0 ${formErrors.userId ? "is-invalid" : ""}`}
                              disabled={isEmailLinkClicked}
                            />
                            {!isEmailLinkClicked && (
                              <button
                                type="button"
                                className="position-absolute top-0 end-0 email-toggle-icon"
                                onClick={checkEmailVerifyBtn}
                              >
                                <MdVerified />
                              </button>
                            )}
                          </div>
                          {formErrors.userId && (
                            <p className="text-danger">{formErrors.userId}</p>
                          )}
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="d-flex align-align-items-center flex-column sign-up-form-group">
                          <label htmlFor="password" className="text-capitalize mb-1">
                            Password
                          </label>
                          <div className="position-relative sign-up-password-group">
                            <input
                              type={showPassword ? "text" : "password"}
                              id="password"
                              autoComplete="off"
                              value={password}
                              onChange={handlePasswordChange}
                              className={`px-2 rounded-0 ${formErrors.password ? "is-invalid" : ""}`}
                              disabled={!isEmailVerified}
                              style={{ cursor: isEmailVerified ? "text" : "not-allowed" }}
                            />
                            <button
                              type="button"
                              className="position-absolute top-0 end-0 password-toggle-icon"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                          {formErrors.password && (
                            <p className="text-danger">{formErrors.password}</p>
                          )}
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="d-flex align-align-items-center flex-column mb-4 sign-up-form-group">
                          <label htmlFor="confirm-password" className="text-capitalize mb-1">
                            Confirm Password
                          </label>
                          <div className="position-relative sign-up-password-group">
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              id="confirm-password"
                              autoComplete="off"
                              value={confirmPassword}
                              onChange={handleConfirmPasswordChange}
                              className={`px-2 rounded-0 ${formErrors.confirmPassword ? "is-invalid" : ""}`}
                              disabled={!isEmailVerified}
                              style={{ cursor: isEmailVerified ? "text" : "not-allowed" }}
                            />
                            <button
                              type="button"
                              className="position-absolute top-0 end-0 password-toggle-icon"
                              onClick={toggleConfirmPasswordVisibility}
                            >
                              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                          {formErrors.confirmPassword && (
                            <p className="text-danger">{formErrors.confirmPassword}</p>
                          )}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="border-0 fw-semibold text-capitalize sign-up-form-button"
                        disabled={!isEmailVerified}
                      >
                        Sign Up
                      </button>
                      <p className="fs-6 mb-0 mt-2 text-end sign-up-register-para">
                        Returning Customer {"->"}{" "}
                        <Link to={"/sign-in"} className="fw-bold sign-up-register-link">
                          Sign In Now
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
