import React from "react";
import { Link } from "react-router-dom";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";

const BusinessToBusiness = () => {
    return (
        <>
          <section className="b2b-page">
            <div className="our-container px-3">
              <div className="inner-container">
                <div className="b2b-page-heading mb-4 d-flex align-items-center justify-content-between">
                  <div className="">
                    <h2>B2B Products</h2>
                  </div>
                  <div className="b2b-heading d-flex align-items-center justify-content-center">
                    {/* <form action="">
                      <div className="search-box-input">
                        <input
                          type="text"
                          placeholder="Search..."
                        //   value={searchTerm}
                        //   onChange={handleSearchChange}
                        />
                      </div>
                    </form> */}
                    <Link
                      to={"/add-b2b"}
                      className="b2b-page-heading-add-link fs-6"
                    >
                      <FaPlus className="plus-icon-add" />
                      <span>Add</span>
                    </Link>
                  </div>
                </div>
                <>
                    <div className="b2b-page-body table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Title</th>
                            <th scope="col">Price</th>
                            <th scope="col">Image</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                        </tbody>
                      </table>
                      {/* <div className="pagination-controls">
                        <label>
                          Show
                          <select value={pageSize} onChange={handlePageSizeChange}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                          </select>
                          b2bs per Page
                        </label>
                        <div className="pagination-buttons">
                          <button
                            className="pagination-btn"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                          >
                            Previous
                          </button>
                          <span>
                            Page {currentPage} of {totalPages}
                          </span>
                          <button
                            className="pagination-btn"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage >= totalPages}
                          >
                            Next
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </>
              </div>
            </div>
          </section>
        </>
      );
}

export default BusinessToBusiness;