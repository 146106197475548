import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import imageCompression from "browser-image-compression";
import { useNavigate, useParams } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { ProductContext } from "../../contexts/ProductContext";
import { toast } from "react-toastify";
import { MdArrowBackIos } from "react-icons/md";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import ReactQuill from "react-quill";

const EditProduct = () => {
  const { pkProdId } = useParams();
  const navigate = useNavigate();
  const { getProductDetails } = useContext(ProductContext);

  const [product, setProduct] = useState({
    prodName: "",
    prodPrice: "",
    quantity: 0,
    prodDescription: "",
    isActive: false,
    isCustomized: false,
    images: [],
    imageColor: "#000000",
    imgName: [],
    prodCategory: "",
    prodType: "",
  });

  const [productTypes, setProductTypes] = useState([]);

  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/home/productTypeList`
        );
        console.log("Product Types Response:", response.data); // Debug log
        if (response.data && response.data.option) {
          setProductTypes(response.data.option);
        }
      } catch (error) {
        console.error("Error fetching product types:", error);
      }
    };

    fetchProductTypes();
  }, []);

  useEffect(() => {
    const fetchProductDetails = async () => {
      const product = await getProductDetails(pkProdId);
      if (product) {
        const info = product.info && product.info[0] ? product.info[0] : {};
        setProduct({
          prodName: product.prodName,
          prodPrice: product.prodPrice,
          quantity: info.quantity,
          prodDescription: product.prodDesc,
          isActive: product.isActive === "1",
          isCustomized: product.isCustomized === "1",
          images: info.imagePath ? info.imagePath.map((path) => path) : [],
          imageColor: info.imageColor || "#000000",
          imgName: info.imageName ? [info.imageName] : [],
          prodCategory: product.prodCategory || "",
          prodType: product.prodType || "",
        });
      }
    };

    fetchProductDetails();
  }, [pkProdId, getProductDetails]);

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    const compressedFiles = await Promise.all(
      files.map(async (file) => {
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
        });
        return compressedFile;
      })
    );
    setProduct((prevProduct) => ({
      ...prevProduct,
      images: [...prevProduct.images, ...compressedFiles],
      imgName: [...prevProduct.imgName, ...files.map((file) => file.name)],
    }));
  };

  const handleRemoveImage = (index) => {
    setProduct((prevProduct) => {
      const newImages = [...prevProduct.images];
      newImages.splice(index, 1);
      const newImgNames = [...prevProduct.imgName];
      newImgNames.splice(index, 1);
      return { ...prevProduct, images: newImages, imgName: newImgNames };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        prodName: product.prodName,
        prodPrice: product.prodPrice.toString(),
        quantity: product.quantity,
        prodDescription: product.prodDescription,
        isActive: product.isActive ? "1" : "0",
        imageColor: product.imageColor,
        imgName: product.imgName.join(","),
        prodCategory: product.prodCategory,
        prodType: product.prodType,
        customize: product.customize,
      })
    );
    product.images.forEach((image, index) => {
      if (image instanceof Blob) {
        formData.append(`files`, image, product.imgName[index]);
      }
    });

    console.log("Submitting form data:", formData);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/product/update/${pkProdId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("Response from API:", response.data);
      toast.success("Updated!", {
        autoClose: 1000,
        position: "bottom-right",
      });
      navigate("/product");
    } catch (error) {
      console.error("Error updating product:", error);
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while updating the product.";
      toast.error(errorMessage, {
        autoClose: 1000,
        position: "bottom-right",
      });
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleDescriptionChange = (value) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      prodDescription: value,
    }));
  };

  return (
    <section className="edit-product-page">
      <div className="our-container px-3">
        <div className="inner-container">
          <div className="edit-product-heading mb-4">
            <h2>Edit Product</h2>
          </div>
          <div className="edit-product-body col-md-8 col-lg-8 col-12 mx-auto">
            <button
              onClick={handleBackClick}
              className="back-btn-per-page mb-5"
            >
              <MdArrowBackIos className="back-icon-per-page" />
              Back
            </button>
            <form className="row g-3" onSubmit={handleSubmit}>
              <div className="col-12">
                <label htmlFor="prodName" className="form-label fw-medium">
                  Product Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="prodName"
                  placeholder="Product Name"
                  value={product.prodName}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <div className="col-12">
                <label htmlFor="prodPrice" className="form-label fw-medium">
                  Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="prodPrice"
                  placeholder="Price"
                  value={product.prodPrice}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <div className="col-12">
                <label htmlFor="quantity" className="form-label fw-medium">
                  Quantity
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="quantity"
                  placeholder="Quantity"
                  value={product.quantity}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              {/* <div className="col-12">
                <label htmlFor="prodDescription" className="form-label fw-medium">
                  Product Description
                </label>
                <textarea
                  className="form-control"
                  id="prodDescription"
                  rows="3"
                  placeholder="Product Description"
                  value={product.prodDescription}
                  onChange={handleChange}
                  autoComplete="off"
                ></textarea>
              </div> */}
              <div className="col-12">
                <label
                  htmlFor="prodDescription"
                  className="form-label fw-medium"
                >
                  Product Description
                </label>
                <ReactQuill
                  value={product.prodDescription}
                  onChange={handleDescriptionChange}
                  id="prodDescription"
                  placeholder="Product Description"
                  autoComplete="off"
                />
              </div>
              <div className="col-2">
                <label htmlFor="imageColor" className="form-label fw-medium">
                  Image Color
                </label>
                <input
                  type="color"
                  className="form-control"
                  id="imageColor"
                  placeholder="Image Color"
                  value={product.imageColor}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <div className="col-12 mb-3">
                <div className="row">
                  <div className="col-4 mb-3">
                    <label htmlFor="imagePath" className="form-label fw-medium">
                      Images
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="imagePath"
                      title="Choose your images"
                      multiple
                      onChange={handleFileChange}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-12 only-images-name">
                    {product.images.map((image, index) => (
                      <div key={index} className="uploaded-image">
                        <img
                          src={
                            image instanceof Blob
                              ? URL.createObjectURL(image)
                              : image
                          }
                          alt={`Uploaded ${index}`}
                        />
                        <RxCross2
                          className="img-cross-icon"
                          onClick={() => handleRemoveImage(index)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex gap-2">
                <label htmlFor="isActive" className="form-label fw-medium">
                  Active
                </label>
                <input
                  type="checkbox"
                  className="form-check"
                  id="isActive"
                  checked={product.isActive}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <div className="col-12 d-flex gap-2">
                <label htmlFor="isCustomized" className="form-label fw-medium">
                  Customize
                </label>
                <input
                  type="checkbox"
                  className="form-check"
                  id="isCustomized"
                  checked={product.isCustomized}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <div className="col-12">
                <label htmlFor="prodCategory" className="form-label fw-medium">
                  Product Category
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="prodCategory"
                  placeholder="Product Category"
                  value={product.prodCategory}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <div className="col-12">
                <label htmlFor="prodType" className="form-label fw-medium">
                  Product Type
                </label>
                <select
                  className="form-control"
                  id="prodType"
                  value={product.prodType}
                  onChange={handleChange}
                >
                  <option value="">Select Product Type</option>
                  {productTypes.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 mt-4">
                <button
                  type="submit"
                  className="btn btn-primary col-12 d-flex align-items-center justify-content-center mx-auto edit-btn"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditProduct;

// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import imageCompression from "browser-image-compression";
// import { useNavigate, useParams } from "react-router-dom";
// import { RxCross2 } from "react-icons/rx";
// import { ProductContext } from "../../contexts/ProductContext";
// import { toast } from "react-toastify";
// import { MdArrowBackIos } from "react-icons/md";

// const EditProduct = () => {
//   const { pkProdId } = useParams();
//   const navigate = useNavigate();
//   const { getProductDetails } = useContext(ProductContext);

//   const [product, setProduct] = useState({
//     prodName: "",
//     prodPrice: "",
//     quantity: 0,
//     prodDescription: "",
//     customMessage: "",
//     zodiacSign: "",
//     isActive: false,
//     images: [],
//     imageColor: "#000000",
//     imgName: [],
//     prodCategory: "",
//   });

//   useEffect(() => {
//     const fetchProductDetails = async () => {
//       const product = await getProductDetails(pkProdId);
//       if (product) {
//         const info = product.info && product.info[0] ? product.info[0] : {};
//         setProduct({
//           prodName: product.prodName,
//           prodPrice: product.prodPrice,
//           quantity: info.quantity,
//           prodDescription: product.prodDesc,
//           customMessage: info.customMessage || "",
//           zodiacSign: info.zodiacSign || "",
//           isActive: product.isActive === "1",
//           images: info.imagePath ? info.imagePath.map((path) => path) : [],
//           imageColor: info.imageColor || "#000000",
//           imgName: info.imageName ? [info.imageName] : [],
//           prodCategory: product.prodCategory || "",
//         });
//       }
//     };

//     fetchProductDetails();
//   }, [pkProdId, getProductDetails]);

//   const handleChange = (e) => {
//     const { id, value, type, checked } = e.target;
//     setProduct((prevProduct) => ({
//       ...prevProduct,
//       [id]: type === "checkbox" ? checked : value,
//     }));
//   };

//   const handleFileChange = async (e) => {
//     const files = Array.from(e.target.files);
//     const compressedFiles = await Promise.all(
//       files.map(async (file) => {
//         const compressedFile = await imageCompression(file, {
//           maxSizeMB: 1,
//           maxWidthOrHeight: 1920,
//         });
//         return compressedFile;
//       })
//     );
//     setProduct((prevProduct) => ({
//       ...prevProduct,
//       images: [...prevProduct.images, ...compressedFiles],
//       imgName: [...prevProduct.imgName, ...files.map((file) => file.name)],
//     }));
//   };

//   const handleRemoveImage = (index) => {
//     setProduct((prevProduct) => {
//       const newImages = [...prevProduct.images];
//       newImages.splice(index, 1);
//       const newImgNames = [...prevProduct.imgName];
//       newImgNames.splice(index, 1);
//       return { ...prevProduct, images: newImages, imgName: newImgNames };
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Create FormData instance
//     const formData = new FormData();

//     // Append data as JSON string
//     formData.append(
//       "data",
//       JSON.stringify({
//         prodName: product.prodName,
//         prodPrice: product.prodPrice.toString(),
//         quantity: product.quantity,
//         prodDescription: product.prodDescription,
//         customMessage: product.customMessage,
//         zodiacSign: product.zodiacSign,
//         isActive: product.isActive ? "1" : "0",
//         imageColor: product.imageColor,
//         imgName: product.imgName.join(","),
//         prodCategory: product.prodCategory,
//       })
//     );

//     // Append files
//     product.images.forEach((image, index) => {
//       if (image instanceof Blob) {
//         formData.append(`files`, image, product.imgName[index]);
//       }
//     });

//     console.log("Submitting form data:", formData); // Debug log

//     try {
//       const response = await axios.put(
//         `${process.env.REACT_APP_API_URL}/product/update/${pkProdId}`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );
//       console.log("Response from API:", response.data); // Debug log
//       toast.success("Updated!");
//       navigate("/product");
//     } catch (error) {
//       console.error("Error updating product:", error); // Debug log
//       const errorMessage =
//         error.response?.data?.message ||
//         "An error occurred while updating the product.";
//       toast.error(errorMessage);
//     }
//   };

//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();

//   //   const formData = new FormData();
//   //   formData.append(
//   //     "data",
//   //     JSON.stringify({
//   //       prodName: product.prodName,
//   //       prodPrice: product.prodPrice.toString(),
//   //       quantity: product.quantity,
//   //       prodDescription: product.prodDescription,
//   //       customMessage: product.customMessage,
//   //       zodiacSign: product.zodiacSign,
//   //       isActive: product.isActive ? "1" : "0",
//   //       imageColor: product.imageColor,
//   //       imgName: product.imgName.join(","),
//   //       prodCategory: product.prodCategory,
//   //     })
//   //   );

//   //   product.images.forEach((image, index) => {
//   //     if (image instanceof Blob) {
//   //       formData.append(`files`, image, product.imgName[index]);
//   //     }
//   //   });

//   //   try {
//   //     await axios.put(
//   //       `${process.env.REACT_APP_API_URL}/product/update/${pkProdId}`,
//   //       formData,
//   //       {
//   //         headers: {
//   //           "Content-Type": "multipart/form-data",
//   //           Authorization: `Bearer ${localStorage.getItem("token")}`,
//   //         },
//   //       }
//   //     );
//   //     toast.success("Updated!");
//   //     navigate("/product");
//   //   } catch (error) {
//   //     const errorMessage =
//   //       error.response?.data?.message ||
//   //       "An error occurred while updating the product.";
//   //     toast.error(errorMessage);
//   //   }
//   // };

//   const handleBackClick = () => {
//     navigate(-1);
//   };

//   return (
//     <section className="edit-product-page">
//       <div className="our-container px-3">
//         <div className="inner-container">
//           <div className="edit-product-heading mb-4">
//             <h2>Edit Product</h2>
//           </div>
//           <div className="edit-product-body col-md-8 col-lg-8 col-12 mx-auto">
//             <button
//               onClick={handleBackClick}
//               className="back-btn-per-page mb-5"
//             >
//               <MdArrowBackIos className="back-icon-per-page" />
//               Back
//             </button>
//             <form className="row g-3" onSubmit={handleSubmit}>
//               <div className="col-12">
//                 <label htmlFor="prodName" className="form-label fw-medium">
//                   Product Name
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="prodName"
//                   placeholder="Product Name"
//                   value={product.prodName}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12">
//                 <label htmlFor="prodPrice" className="form-label fw-medium">
//                   Price
//                 </label>
//                 <input
//                   type="number"
//                   className="form-control"
//                   id="prodPrice"
//                   placeholder="Price"
//                   value={product.prodPrice}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12">
//                 <label htmlFor="quantity" className="form-label fw-medium">
//                   Quantity
//                 </label>
//                 <input
//                   type="number"
//                   className="form-control"
//                   id="quantity"
//                   placeholder="Quantity"
//                   value={product.quantity}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12">
//                 <label
//                   htmlFor="prodDescription"
//                   className="form-label fw-medium"
//                 >
//                   Product Description
//                 </label>
//                 <textarea
//                   className="form-control"
//                   id="prodDescription"
//                   rows="3"
//                   placeholder="Product Description"
//                   value={product.prodDescription}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 ></textarea>
//               </div>
//               <div className="col-2">
//                 <label htmlFor="imageColor" className="form-label fw-medium">
//                   Image Color
//                 </label>
//                 <input
//                   type="color"
//                   className="form-control"
//                   id="imageColor"
//                   placeholder="Image Color"
//                   value={product.imageColor}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12 mb-3">
//                 <div className="row">
//                   <div className="col-4 mb-3">
//                     <label htmlFor="imagePath" className="form-label fw-medium">
//                       Images
//                     </label>
//                     <input
//                       type="file"
//                       className="form-control"
//                       id="imagePath"
//                       title="Choose your images"
//                       multiple
//                       onChange={handleFileChange}
//                       autoComplete="off"
//                     />
//                   </div>
//                   <div className="col-12 only-images-name">
//                     {product.images.map((image, index) => (
//                       <div key={index} className="uploaded-image">
//                         <img
//                           src={
//                             image instanceof Blob
//                               ? URL.createObjectURL(image)
//                               : image
//                           }
//                           alt={`Uploaded ${index}`}
//                         />
//                         <RxCross2
//                           onClick={() => handleRemoveImage(index)}
//                           className="img-cross-icon"
//                         />
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12 d-flex gap-2">
//                 <label htmlFor="isActive" className="form-label fw-medium">
//                   Active
//                 </label>
//                 <span>
//                   <input
//                     type="checkbox"
//                     id="isActive"
//                     className="checkbox"
//                     checked={product.isActive}
//                     onChange={handleChange}
//                   />
//                   <label htmlFor="isActive" className="toggle"></label>
//                 </span>
//               </div>
//               <div className="col-12 d-flex flex-column">
//                 <label
//                   htmlFor="prodCategory"
//                   className="form-label fw-medium m-0"
//                 >
//                   Product Category
//                 </label>
//                 <select
//                   className="form-select"
//                   id="prodCategory"
//                   value={product.prodCategory}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Category</option>
//                   <option value="Customized">Customized</option>
//                   <option value="Non-Customized">Non-Customized</option>
//                 </select>
//               </div>
//               <div className="col-12 mt-5">
//                 <button
//                   type="submit"
//                   className="btn btn-primary col-12 d-flex align-items-center justify-content-center mx-auto edit-btn"
//                 >
//                   Update Product
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default EditProduct;

// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import imageCompression from "browser-image-compression";
// import { useNavigate, useParams } from "react-router-dom";
// import { RxCross2 } from "react-icons/rx";
// import { ProductContext } from "../../contexts/ProductContext";
// import { toast } from "react-toastify";
// import { MdArrowBackIos } from "react-icons/md";

// const EditProduct = () => {
//   const { pkProdId } = useParams();
//   const navigate = useNavigate();
//   const { getProductDetails } = useContext(ProductContext);

//   const [product, setProduct] = useState({
//     prodName: "",
//     prodPrice: "",
//     quantity: 0,
//     prodDescription: "",
//     customMessage: "",
//     zodiacSign: "",
//     isActive: false,
//     images: [],
//     imageColor: "#000000",
//     imgName: [],
//     prodCategory: "",
//   });

//   useEffect(() => {
//     const product = getProductDetails(pkProdId);
//     if (product) {
//       const info = product.info && product.info[0] ? product.info[0] : {};
//       setProduct({
//         prodName: product.prodName,
//         prodPrice: product.prodPrice,
//         quantity: info.quantity,
//         prodDescription: product.prodDesc,
//         customMessage: "",
//         zodiacSign: "",
//         isActive: product.isActive === "1",
//         images: info.imagePath ? info.imagePath.map((path) => path) : [],
//         imageColor: info.imageColor || "#000000",
//         imgName: info.imageName ? [info.imageName] : [],
//         prodCategory: product.prodCategory || "",
//       });
//     }
//   }, [pkProdId, getProductDetails]);

//   const handleChange = (e) => {
//     const { id, value, type, checked } = e.target;
//     setProduct((prevProduct) => ({
//       ...prevProduct,
//       [id]: type === "checkbox" ? checked : value,
//     }));
//   };

//   const handleFileChange = async (e) => {
//     const files = Array.from(e.target.files);
//     const compressedFiles = await Promise.all(
//       files.map(async (file) => {
//         const compressedFile = await imageCompression(file, {
//           maxSizeMB: 1,
//           maxWidthOrHeight: 1920,
//         });
//         return compressedFile;
//       })
//     );
//     setProduct((prevProduct) => ({
//       ...prevProduct,
//       images: [...prevProduct.images, ...compressedFiles],
//       imgName: [...prevProduct.imgName, ...files.map((file) => file.name)],
//     }));
//   };

//   const handleRemoveImage = (index) => {
//     setProduct((prevProduct) => {
//       const newImages = [...prevProduct.images];
//       newImages.splice(index, 1);
//       const newImgNames = [...prevProduct.imgName];
//       newImgNames.splice(index, 1);
//       return { ...prevProduct, images: newImages, imgName: newImgNames };
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const formData = new FormData();
//     formData.append(
//       "data",
//       JSON.stringify({
//         prodName: product.prodName,
//         prodPrice: product.prodPrice.toString(),
//         quantity: product.quantity,
//         prodDescription: product.prodDescription,
//         customMessage: product.customMessage,
//         zodiacSign: product.zodiacSign,
//         isActive: product.isActive ? "1" : "0",
//         imageColor: product.imageColor,
//         imgName: product.imgName.join(","),
//         prodCategory: product.prodCategory,
//       })
//     );

//     product.images.forEach((image, index) => {
//       if (image instanceof Blob) {
//         formData.append(`files`, image, product.imgName[index]);
//       }
//     });

//     try {
//       await axios.put(
//         `${process.env.REACT_APP_API_URL}/product/update/${pkProdId}`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );
//       toast.success("Updated!");
//       navigate("/product");
//     } catch (error) {
//       const errorMessage =
//         error.response?.data?.message ||
//         "An error occurred while updating the product.";
//       toast.error(errorMessage);
//     }
//   };

//   const handleBackClick = () => {
//     navigate(-1);
//   };

//   return (
//     <section className="edit-product-page">
//       <div className="our-container px-3">
//         <div className="inner-container">
//           <div className="edit-product-heading mb-4">
//             <h2>Edit Product</h2>
//           </div>
//           <div className="edit-product-body col-md-8 col-lg-8 col-12 mx-auto">
//             <button
//               onClick={handleBackClick}
//               className="back-btn-per-page mb-5"
//             >
//               <MdArrowBackIos className="back-icon-per-page" />
//               Back
//             </button>
//             <form className="row g-3" onSubmit={handleSubmit}>
//               <div className="col-12">
//                 <label htmlFor="prodName" className="form-label fw-medium">
//                   Product Name
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="prodName"
//                   placeholder="Product Name"
//                   value={product.prodName}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12">
//                 <label htmlFor="prodPrice" className="form-label fw-medium">
//                   Price
//                 </label>
//                 <input
//                   type="number"
//                   className="form-control"
//                   id="prodPrice"
//                   placeholder="Price"
//                   value={product.prodPrice}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12">
//                 <label htmlFor="quantity" className="form-label fw-medium">
//                   Quantity
//                 </label>
//                 <input
//                   type="number"
//                   className="form-control"
//                   id="quantity"
//                   placeholder="Quantity"
//                   value={product.quantity}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12">
//                 <label
//                   htmlFor="prodDescription"
//                   className="form-label fw-medium"
//                 >
//                   Product Description
//                 </label>
//                 <textarea
//                   className="form-control"
//                   id="prodDescription"
//                   rows="3"
//                   placeholder="Product Description"
//                   value={product.prodDescription}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 ></textarea>
//               </div>
//               <div className="col-2">
//                 <label htmlFor="imageColor" className="form-label fw-medium">
//                   Image Color
//                 </label>
//                 <input
//                   type="color"
//                   className="form-control"
//                   id="imageColor"
//                   placeholder="Image Color"
//                   value={product.imageColor}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12 mb-3">
//                 <div className="row">
//                   <div className="col-4 mb-3">
//                     <label htmlFor="imagePath" className="form-label fw-medium">
//                       Images
//                     </label>
//                     <input
//                       type="file"
//                       className="form-control"
//                       id="imagePath"
//                       title="Choose your images"
//                       multiple
//                       onChange={handleFileChange}
//                       autoComplete="off"
//                     />
//                   </div>
//                   <div className="col-12 only-images-name">
//                     {product.images.map((image, index) => (
//                       <div key={index} className="uploaded-image">
//                         <img
//                           src={
//                             image instanceof Blob
//                               ? URL.createObjectURL(image)
//                               : image
//                           }
//                           alt={`Uploaded ${index}`}
//                         />
//                         <RxCross2
//                           onClick={() => handleRemoveImage(index)}
//                           className="img-cross-icon"
//                         />
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12 d-flex gap-2">
//                 <label htmlFor="isActive" className="form-label fw-medium">
//                   Active
//                 </label>
//                 <span>
//                   <input
//                     type="checkbox"
//                     id="isActive"
//                     className="checkbox"
//                     checked={product.isActive}
//                     onChange={handleChange}
//                   />
//                   <label htmlFor="isActive" className="toggle"></label>
//                 </span>
//               </div>
//               <div className="col-12 d-flex flex-column">
//                 <label
//                   htmlFor="prodCategory"
//                   className="form-label fw-medium m-0"
//                 >
//                   Product Category
//                 </label>
//                 <select
//                   className="form-select"
//                   id="prodCategory"
//                   value={product.prodCategory}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Category</option>
//                   <option value="Customized">Customized</option>
//                   <option value="Non-Customized">Non-Customized</option>
//                 </select>
//               </div>
//               <div className="col-12 mt-5">
//                 <button
//                   type="submit"
//                   className="btn btn-primary col-12 d-flex align-items-center justify-content-center mx-auto edit-btn"
//                 >
//                   Update Product
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default EditProduct;
