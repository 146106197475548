import React from "react";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const AddBusinessToBusiness = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <section className="add-b2b-page">
        <div className="our-container px-3">
          <div className="inner-container">
            <div className="add-b2b-heading mb-4">
              <h2>Add B2B Product</h2>
            </div>
            <div className="add-b2b-body col-md-8 col-lg-8 col-12 mx-auto">
              <button
                onClick={handleBackClick}
                className="back-btn-per-page mb-5"
              >
                <MdArrowBackIos className="back-icon-per-page" />
                Back
              </button>
              <form
                className="row g-3"
                // onSubmit={handleSubmit}
              >
                <div className="col-12">
                  <label htmlFor="bTobName" className="form-label fw-medium">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="bTobName"
                    placeholder="Title"
                    //   value={b2b.prodName}
                    //   onChange={handleChange}
                    autoComplete="off"
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="bTobPrice" className="form-label fw-medium">
                    Price
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="bTobPrice"
                    placeholder="Price"
                    //   value={b2b.prodPrice}
                    //   onChange={handleChange}
                    autoComplete="off"
                  />
                </div>
                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-4 mb-3">
                      <label
                        htmlFor="imagePath"
                        className="form-label fw-medium"
                      >
                        Images
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="imagePath"
                        title="Choose your images"
                        multiple
                        //   onChange={handleFileChange}
                        autoComplete="off"
                      />
                    </div>
                    {/* <div className="col-12 only-images-name">
                    {b2b.images.map((image, index) => (
                      <div key={index} className="uploaded-image">
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`b2b ${index}`}
                        />
                        <RxCross2
                          onClick={() => handleRemoveImage(index)}
                          className="img-cross-icon"
                        />
                      </div>
                    ))}
                  </div> */}
                  </div>
                </div>
                {/* <div className="col-12 d-flex gap-2">
                <label htmlFor="isActive" className="form-label fw-medium">
                  Active
                </label>
                <span>
                  <input
                    type="checkbox"
                    id="isActive"
                    className="checkbox"
                    checked={b2b.isActive}
                    onChange={handleChange}
                  />
                  <label htmlFor="isActive" className="toggle"></label>
                </span>
              </div> */}
                <div className="col-12 mt-5">
                  <button
                    type="submit"
                    className="btn btn-primary col-12 d-flex align-items-center justify-content-center mx-auto"
                  >
                    Add b2b Product
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddBusinessToBusiness;
