import React, { useEffect, useState } from "react";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ProductType = () => {
  const navigate = useNavigate();

  const [productTypes, setProductTypes] = useState([]);
  const [editProductType, setEditProductType] = useState({
    id: null,
    prodType: "",
    isActive: false,
  });
  const [newProductType, setNewProductType] = useState({
    prodType: "",
    isActive: false,
  });

  // Fetch product types on component mount
  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/prodType/search`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              pkProdTypeId: "",
              prodType: "",
              isActive: "",
              pageSize: 10,
              pageNo: 1,
            }),
          }
        );
        const data = await response.json();
        if (data.status === "Successful") {
          setProductTypes(data.data);
        } else {
          console.error("Failed to fetch product types:", data.message);
        }
      } catch (error) {
        console.error("Error fetching product types:", error);
      }
    };

    fetchProductTypes();
  }, []);

  const handleEditClick = (index) => {
    const selectedProductType = productTypes[index];
    setEditProductType({
      id: selectedProductType.pkProdTypeId,
      prodType: selectedProductType.productType,
      isActive: selectedProductType.isActive === "1",
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditProductType((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleNewProductTypeChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewProductType((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/prodType/update/${editProductType.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            prodType: editProductType.prodType,
            isActive: editProductType.isActive ? "1" : "0",
          }),
        }
      );

      const data = await response.json();
      if (data.status === "Successful") {
        setProductTypes((prev) =>
          prev.map((type) =>
            type.pkProdTypeId === editProductType.id
              ? {
                  ...type,
                  productType: editProductType.prodType,
                  isActive: editProductType.isActive ? "1" : "0",
                }
              : type
          )
        );
        navigate("/");
        // Close the edit modal programmatically
        const editModal = new window.bootstrap.Modal(
          document.getElementById("exampleModalEdit")
        );
        editModal.hide();
      } else {
        console.error("Failed to update product type:", data.message);
      }
    } catch (error) {
      console.error("Error updating product type:", error);
    }
  };

  const handleNewProductTypeSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/prodType/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            prodType: newProductType.prodType,
            isActive: newProductType.isActive ? "1" : "0",
          }),
        }
      );

      const data = await response.json();
      if (data.status === "Successful") {
        setProductTypes((prev) => [
          ...prev,
          { ...newProductType, pkProdTypeId: data.newId },
        ]);
        navigate("/");
        // Close the add modal programmatically
        const addModal = new window.bootstrap.Modal(
          document.getElementById("exampleModalAdd")
        );
        addModal.hide();
        setNewProductType({ prodType: "", isActive: false });
      } else {
        console.error("Failed to add product type:", data.message);
      }
    } catch (error) {
      console.error("Error adding product type:", error);
    }
  };

  const handleDeleteClick = async (pkProdTypeId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this product type?"
    );

    if (confirmDelete) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/prodType/delete/${pkProdTypeId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const data = await response.json();
        if (data.status === "Successful") {
          setProductTypes((prev) =>
            prev.filter((type) => type.pkProdTypeId !== pkProdTypeId)
          );
        } else {
          console.error("Failed to delete product type:", data.message);
        }
      } catch (error) {
        console.error("Error deleting product type:", error);
      }
    }
  };

  return (
    <>
      <section className="product-type-page">
        <div className="our-container px-3">
          <div className="inner-container">
            <div className="product-type-page-heading mb-4 d-flex align-items-center justify-content-between">
              <div className="">
                <h2>Product Types</h2>
              </div>
              <div className="product-type-heading d-flex align-items-center justify-content-center">
                <button
                  type="button"
                  className="btn product-type-page-heading-add-link"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalAdd"
                >
                  <FaPlus className="plus-icon-add" />
                  <span>Add</span>
                </button>
              </div>
            </div>
            <div className="product-type-page-body table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Product Type</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {productTypes.length > 0 ? (
                    productTypes.map((productType, index) => (
                      <tr key={productType.pkProdTypeId}>
                        <td>{index + 1}</td>
                        <td>{productType.productType}</td>
                        <td>
                          <button
                            type="button"
                            className="btn action-icon-edit-link"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModalEdit"
                            onClick={() => handleEditClick(index)}
                          >
                            <FaEdit />
                          </button>
                          <button
                            className="action-icon-delete-btn"
                            onClick={() =>
                              handleDeleteClick(productType.pkProdTypeId)
                            }
                          >
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No product types available.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      {/* Add Product Type Modal */}
      <div
        className="modal fade product-type-modal"
        id="exampleModalAdd"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Add Product Type
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModalBtnAdd"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleNewProductTypeSubmit}>
                <div className="d-flex align-items-center flex-column mb-4 product-type-form-group">
                  <input
                    type="text"
                    id="newProdType"
                    name="prodType"
                    value={newProductType.prodType}
                    onChange={handleNewProductTypeChange}
                    autoComplete="off"
                    className="px-2 rounded-0"
                    placeholder="Your Product Type"
                  />
                </div>
                <div className="d-flex flex-column mb-4 product-type-form-group">
                  <label htmlFor="newIsActive">Is Active</label>
                  <input
                    type="checkbox"
                    id="newIsActive"
                    name="isActive"
                    checked={newProductType.isActive}
                    onChange={handleNewProductTypeChange}
                    autoComplete="off"
                    className="px-2 rounded-0"
                    style={{ width: "24px" }}
                  />
                </div>
                <button
                  type="submit"
                  className="text-capitalize product-type-form-button"
                >
                  Add
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Product Type Modal */}
      <div
        className="modal fade product-type-modal"
        id="exampleModalEdit"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Edit Product Type
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModalBtnEdit"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleFormSubmit}>
                <div className="d-flex align-items-center flex-column mb-4 product-type-form-group">
                  <input
                    type="text"
                    id="prodType"
                    name="prodType"
                    value={editProductType.prodType}
                    onChange={handleInputChange}
                    autoComplete="off"
                    className="px-2 rounded-0"
                    placeholder="Your Product Type"
                  />
                </div>
                <div className="d-flex flex-column mb-4 product-type-form-group">
                  <label htmlFor="isActive">Is Active</label>
                  <input
                    type="checkbox"
                    id="isActive"
                    name="isActive"
                    checked={editProductType.isActive}
                    onChange={handleInputChange}
                    autoComplete="off"
                    className="px-2 rounded-0"
                    style={{ width: "24px" }}
                  />
                </div>
                <button
                  type="submit"
                  className="text-capitalize product-type-form-button"
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductType;

// import React, { useEffect, useState } from "react";
// import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";

// const ProductType = () => {
//   const [productTypes, setProductTypes] = useState([]);
//   const [editProductType, setEditProductType] = useState({
//     id: null,
//     prodType: "",
//     isActive: false,
//   });
//   const [newProductType, setNewProductType] = useState({
//     prodType: "",
//     isActive: false,
//   });

//   useEffect(() => {
//     const fetchProductTypes = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/prodType/search`,
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//             body: JSON.stringify({
//               pkProdTypeId: "", // placeholder, remove if not needed
//               prodType: "",
//               isActive: "",
//               pageSize: 10, // adjust as needed
//               pageNo: 1, // adjust as needed
//             }),
//           }
//         );
//         const data = await response.json();
//         if (data.status === "Successful") {
//           setProductTypes(data.data); // Update to use 'data' field
//         } else {
//           console.error("Failed to fetch product types:", data.message);
//         }
//       } catch (error) {
//         console.error("Error fetching product types:", error);
//       }
//     };

//     fetchProductTypes();
//   }, []);

//   const handleEditClick = (index) => {
//     const selectedProductType = productTypes[index];
//     setEditProductType({
//       id: selectedProductType.pkProdTypeId, // Assuming pkProdTypeId is used for editing
//       prodType: selectedProductType.productType, // Update field to 'productType'
//       isActive: selectedProductType.isActive === "1",
//     });
//   };

//   const handleInputChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setEditProductType((prev) => ({
//       ...prev,
//       [name]: type === "checkbox" ? checked : value,
//     }));
//   };

//   const handleNewProductTypeChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setNewProductType((prev) => ({
//       ...prev,
//       [name]: type === "checkbox" ? checked : value,
//     }));
//   };

//   const handleFormSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/prodType/update/${editProductType.id}`,
//         {
//           method: "PUT",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             prodType: editProductType.prodType,
//             isActive: editProductType.isActive ? "1" : "0",
//           }),
//         }
//       );

//       const data = await response.json();
//       if (data.status === "Successful") {
//         setProductTypes((prev) =>
//           prev.map((type) =>
//             type.pkProdTypeId === editProductType.id
//               ? { ...type, productType: editProductType.prodType } // Update field to 'productType'
//               : type
//           )
//         );
//         // Use Bootstrap's modal method to close the modal
//         const editModal = new window.bootstrap.Modal(
//           document.getElementById("exampleModalEdit")
//         );
//         editModal.hide();
//       } else {
//         console.error("Failed to update product type:", data.message);
//       }
//     } catch (error) {
//       console.error("Error updating product type:", error);
//     }
//   };

//   const handleNewProductTypeSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/prodType/add`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             prodType: newProductType.prodType,
//             isActive: newProductType.isActive ? "1" : "0",
//           }),
//         }
//       );

//       const data = await response.json();
//       if (data.status === "Successful") {
//         setProductTypes((prev) => [
//           ...prev,
//           { ...newProductType, pkProdTypeId: data.newId }, // Assuming response contains newId
//         ]);
//         // Use Bootstrap's modal method to close the modal
//         const addModal = new window.bootstrap.Modal(
//           document.getElementById("exampleModalAdd")
//         );
//         addModal.hide();
//         setNewProductType({ prodType: "", isActive: false });
//       } else {
//         console.error("Failed to add product type:", data.message);
//       }
//     } catch (error) {
//       console.error("Error adding product type:", error);
//     }
//   };

//   const handleDeleteClick = async (pkProdTypeId) => {
//     const confirmDelete = window.confirm(
//       "Are you sure you want to delete this product type?"
//     );

//     if (confirmDelete) {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/prodType/delete/${pkProdTypeId}`,
//           {
//             method: "DELETE",
//           }
//         );

//         const data = await response.json();
//         if (data.status === "Successful") {
//           setProductTypes((prev) =>
//             prev.filter((type) => type.pkProdTypeId !== pkProdTypeId)
//           );
//         } else {
//           console.error("Failed to delete product type:", data.message);
//         }
//       } catch (error) {
//         console.error("Error deleting product type:", error);
//       }
//     }
//   };

//   return (
//     <>
//       <section className="product-type-page">
//         <div className="our-container px-3">
//           <div className="inner-container">
//             <div className="product-type-page-heading mb-4 d-flex align-items-center justify-content-between">
//               <div className="">
//                 <h2>Products</h2>
//               </div>
//               <div className="product-type-heading d-flex align-items-center justify-content-center">
//                 <button
//                   type="button"
//                   className="btn product-type-page-heading-add-link"
//                   data-bs-toggle="modal"
//                   data-bs-target="#exampleModalAdd"
//                 >
//                   <FaPlus className="plus-icon-add" />
//                   <span>Add</span>
//                 </button>
//               </div>
//             </div>
//             <div className="product-type-page-body table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <th scope="col">#</th>
//                     <th scope="col">Product Type</th>
//                     <th scope="col">Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {productTypes.length > 0 ? (
//                     productTypes.map((productType, index) => (
//                       <tr key={productType.pkProdTypeId}>
//                         <td>{index + 1}</td>
//                         <td>{productType.productType}</td> {/* Update field to 'productType' */}
//                         <td>
//                           <button
//                             type="button"
//                             className="btn action-icon-edit-link"
//                             data-bs-toggle="modal"
//                             data-bs-target="#exampleModalEdit"
//                             onClick={() => handleEditClick(index)}
//                           >
//                             <FaEdit />
//                           </button>
//                           <button
//                             className="action-icon-delete-btn"
//                             onClick={() =>
//                               handleDeleteClick(productType.pkProdTypeId)
//                             }
//                           >
//                             <FaTrash />
//                           </button>
//                         </td>
//                       </tr>
//                     ))
//                   ) : (
//                     <tr>
//                       <td colSpan="3">No product types available.</td>
//                     </tr>
//                   )}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Add Product Type Modal */}
//       <div
//         className="modal fade product-type-modal"
//         id="exampleModalAdd"
//         tabIndex="-1"
//         aria-labelledby="exampleModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-dialog-centered">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h1 className="modal-title fs-5" id="exampleModalLabel">
//                 Add Product Type
//               </h1>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//                 id="closeModalBtnAdd"
//               ></button>
//             </div>
//             <div className="modal-body">
//               <form onSubmit={handleNewProductTypeSubmit}>
//                 <div className="mb-3">
//                   <label htmlFor="prodTypeAdd" className="form-label">
//                     Product Type
//                   </label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="prodTypeAdd"
//                     name="prodType"
//                     value={newProductType.prodType}
//                     onChange={handleNewProductTypeChange}
//                     required
//                   />
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="isActiveAdd" className="form-check-label">
//                     Active
//                   </label>
//                   <input
//                     type="checkbox"
//                     className="form-check-input"
//                     id="isActiveAdd"
//                     name="isActive"
//                     checked={newProductType.isActive}
//                     onChange={handleNewProductTypeChange}
//                   />
//                 </div>
//                 <button type="submit" className="btn btn-primary">
//                   Add Product Type
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Edit Product Type Modal */}
//       <div
//         className="modal fade product-type-modal"
//         id="exampleModalEdit"
//         tabIndex="-1"
//         aria-labelledby="exampleModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-dialog-centered">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h1 className="modal-title fs-5" id="exampleModalLabel">
//                 Edit Product Type
//               </h1>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//                 id="closeModalBtnEdit"
//               ></button>
//             </div>
//             <div className="modal-body">
//               <form onSubmit={handleFormSubmit}>
//                 <div className="mb-3">
//                   <label htmlFor="prodTypeEdit" className="form-label">
//                     Product Type
//                   </label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="prodTypeEdit"
//                     name="prodType"
//                     value={editProductType.prodType}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="isActiveEdit" className="form-check-label">
//                     Active
//                   </label>
//                   <input
//                     type="checkbox"
//                     className="form-check-input"
//                     id="isActiveEdit"
//                     name="isActive"
//                     checked={editProductType.isActive}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <button type="submit" className="btn btn-primary">
//                   Save Changes
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ProductType;

// // import React, { useEffect, useState } from "react";
// // import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";

// // const ProductType = () => {
// //   const [productTypes, setProductTypes] = useState([]);
// //   const [editProductType, setEditProductType] = useState({
// //     id: null,
// //     prodType: "",
// //     isActive: false,
// //   });
// //   const [newProductType, setNewProductType] = useState({
// //     prodType: "",
// //     isActive: false,
// //   });

// //   useEffect(() => {
// //     const fetchProductTypes = async () => {
// //       try {
// //         const response = await fetch(
// //           `${process.env.REACT_APP_API_URL}/prodType/search`,
// //           {
// //             method: "POST",
// //             headers: {
// //               "Content-Type": "application/json",
// //               Authorization: `Bearer ${localStorage.getItem("token")}`,
// //             },
// //             body: JSON.stringify({
// //               pkProdTypeId: "", // placeholder, remove if not needed
// //               prodType: "",
// //               isActive: "",
// //               pageSize: 10, // adjust as needed
// //               pageNo: 1, // adjust as needed
// //             }),
// //           }
// //         );
// //         const data = await response.json();
// //         if (data.status === "Successful") {
// //           setProductTypes(data.option);
// //         } else {
// //           console.error("Failed to fetch product types:", data.message);
// //         }
// //       } catch (error) {
// //         console.error("Error fetching product types:", error);
// //       }
// //     };

// //     fetchProductTypes();
// //   }, []);

// //   const handleEditClick = (index) => {
// //     const selectedProductType = productTypes[index];
// //     setEditProductType({
// //       id: selectedProductType.pkProdTypeId, // Assuming pkProdTypeId is used for editing
// //       prodType: selectedProductType.prodType,
// //       isActive: selectedProductType.isActive === "1",
// //     });
// //   };

// //   const handleInputChange = (e) => {
// //     const { name, value, type, checked } = e.target;
// //     setEditProductType((prev) => ({
// //       ...prev,
// //       [name]: type === "checkbox" ? checked : value,
// //     }));
// //   };

// //   const handleNewProductTypeChange = (e) => {
// //     const { name, value, type, checked } = e.target;
// //     setNewProductType((prev) => ({
// //       ...prev,
// //       [name]: type === "checkbox" ? checked : value,
// //     }));
// //   };

// //   const handleFormSubmit = async (e) => {
// //     e.preventDefault();

// //     try {
// //       const response = await fetch(
// //         `${process.env.REACT_APP_API_URL}/prodType/update/${editProductType.id}`,
// //         {
// //           method: "PUT",
// //           headers: {
// //             "Content-Type": "application/json",
// //           },
// //           body: JSON.stringify({
// //             prodType: editProductType.prodType,
// //             isActive: editProductType.isActive ? "1" : "0",
// //           }),
// //         }
// //       );

// //       const data = await response.json();
// //       if (data.status === "Successful") {
// //         setProductTypes((prev) =>
// //           prev.map((type) =>
// //             type.pkProdTypeId === editProductType.id
// //               ? { ...type, prodType: editProductType.prodType }
// //               : type
// //           )
// //         );
// //         // Use Bootstrap's modal method to close the modal
// //         const editModal = new window.bootstrap.Modal(
// //           document.getElementById("exampleModalEdit")
// //         );
// //         editModal.hide();
// //       } else {
// //         console.error("Failed to update product type:", data.message);
// //       }
// //     } catch (error) {
// //       console.error("Error updating product type:", error);
// //     }
// //   };

// //   const handleNewProductTypeSubmit = async (e) => {
// //     e.preventDefault();

// //     try {
// //       const response = await fetch(
// //         `${process.env.REACT_APP_API_URL}/prodType/add`,
// //         {
// //           method: "POST",
// //           headers: {
// //             "Content-Type": "application/json",
// //           },
// //           body: JSON.stringify({
// //             prodType: newProductType.prodType,
// //             isActive: newProductType.isActive ? "1" : "0",
// //           }),
// //         }
// //       );

// //       const data = await response.json();
// //       if (data.status === "Successful") {
// //         setProductTypes((prev) => [
// //           ...prev,
// //           { ...newProductType, pkProdTypeId: data.newId },
// //         ]); // Assuming response contains newId
// //         // Use Bootstrap's modal method to close the modal
// //         const addModal = new window.bootstrap.Modal(
// //           document.getElementById("exampleModalAdd")
// //         );
// //         addModal.hide();
// //         setNewProductType({ prodType: "", isActive: false });
// //       } else {
// //         console.error("Failed to add product type:", data.message);
// //       }
// //     } catch (error) {
// //       console.error("Error adding product type:", error);
// //     }
// //   };

// //   const handleDeleteClick = async (pkProdTypeId) => {
// //     const confirmDelete = window.confirm(
// //       "Are you sure you want to delete this product type?"
// //     );

// //     if (confirmDelete) {
// //       try {
// //         const response = await fetch(
// //           `${process.env.REACT_APP_API_URL}/prodType/delete/${pkProdTypeId}`,
// //           {
// //             method: "DELETE",
// //           }
// //         );

// //         const data = await response.json();
// //         if (data.status === "Successful") {
// //           setProductTypes((prev) =>
// //             prev.filter((type) => type.pkProdTypeId !== pkProdTypeId)
// //           );
// //         } else {
// //           console.error("Failed to delete product type:", data.message);
// //         }
// //       } catch (error) {
// //         console.error("Error deleting product type:", error);
// //       }
// //     }
// //   };

// //   return (
// //     <>
// //       <section className="product-type-page">
// //         <div className="our-container px-3">
// //           <div className="inner-container">
// //             <div className="product-type-page-heading mb-4 d-flex align-items-center justify-content-between">
// //               <div className="">
// //                 <h2>Products</h2>
// //               </div>
// //               <div className="product-type-heading d-flex align-items-center justify-content-center">
// //                 <button
// //                   type="button"
// //                   className="btn product-type-page-heading-add-link"
// //                   data-bs-toggle="modal"
// //                   data-bs-target="#exampleModalAdd"
// //                 >
// //                   <FaPlus className="plus-icon-add" />
// //                   <span>Add</span>
// //                 </button>
// //               </div>
// //             </div>
// //             <div className="product-type-page-body table-responsive">
// //               <table className="table">
// //                 <thead>
// //                   <tr>
// //                     <th scope="col">#</th>
// //                     <th scope="col">Product Type</th>
// //                     <th scope="col">Actions</th>
// //                   </tr>
// //                 </thead>
// //                 <tbody>
// //                   {productTypes?.data?.length > 0 ? (
// //                     productTypes.map((productType, index) => (
// //                       <tr key={productType.pkProdTypeId}>
// //                         <td>{index + 1}</td>
// //                         <td>{productType.prodType}</td>
// //                         <td>
// //                           <button
// //                             type="button"
// //                             className="btn action-icon-edit-link"
// //                             data-bs-toggle="modal"
// //                             data-bs-target="#exampleModalEdit"
// //                             onClick={() => handleEditClick(index)}
// //                           >
// //                             <FaEdit />
// //                           </button>
// //                           <button
// //                             className="action-icon-delete-btn"
// //                             onClick={() =>
// //                               handleDeleteClick(productType.pkProdTypeId)
// //                             }
// //                           >
// //                             <FaTrash />
// //                           </button>
// //                         </td>
// //                       </tr>
// //                     ))
// //                   ) : (
// //                     <tr>
// //                       <td colSpan="3">No product types available.</td>
// //                     </tr>
// //                   )}
// //                 </tbody>
// //               </table>
// //             </div>
// //           </div>
// //         </div>
// //       </section>

// //       {/* Add Product Type Modal */}
// //       <div
// //         className="modal fade product-type-modal"
// //         id="exampleModalAdd"
// //         tabIndex="-1"
// //         aria-labelledby="exampleModalLabel"
// //         aria-hidden="true"
// //       >
// //         <div className="modal-dialog modal-dialog-centered">
// //           <div className="modal-content">
// //             <div className="modal-header">
// //               <h1 className="modal-title fs-5" id="exampleModalLabel">
// //                 Add Product Type
// //               </h1>
// //               <button
// //                 type="button"
// //                 className="btn-close"
// //                 data-bs-dismiss="modal"
// //                 aria-label="Close"
// //                 id="closeModalBtnAdd"
// //               ></button>
// //             </div>
// //             <div className="modal-body">
// //               <form onSubmit={handleNewProductTypeSubmit}>
// //                 <div className="d-flex align-items-center flex-column mb-4 product-type-form-group">
// //                   <input
// //                     type="text"
// //                     id="newProdType"
// //                     name="prodType"
// //                     value={newProductType.prodType}
// //                     onChange={handleNewProductTypeChange}
// //                     autoComplete="off"
// //                     className="px-2 rounded-0"
// //                     placeholder="Your Product Type"
// //                   />
// //                 </div>
// //                 <div className="d-flex flex-column mb-4 product-type-form-group">
// //                   <label htmlFor="newIsActive">Is Active</label>
// //                   <input
// //                     type="checkbox"
// //                     id="newIsActive"
// //                     name="isActive"
// //                     checked={newProductType.isActive}
// //                     onChange={handleNewProductTypeChange}
// //                     autoComplete="off"
// //                     className="px-2 rounded-0"
// //                     style={{ width: "24px" }}
// //                   />
// //                 </div>
// //                 <button
// //                   type="submit"
// //                   className="text-capitalize product-type-form-button"
// //                 >
// //                   Add
// //                 </button>
// //               </form>
// //             </div>
// //           </div>
// //         </div>
// //       </div>

// //       {/* Edit Product Type Modal */}
// //       <div
// //         className="modal fade product-type-modal"
// //         id="exampleModalEdit"
// //         tabIndex="-1"
// //         aria-labelledby="exampleModalLabel"
// //         aria-hidden="true"
// //       >
// //         <div className="modal-dialog modal-dialog-centered">
// //           <div className="modal-content">
// //             <div className="modal-header">
// //               <h1 className="modal-title fs-5" id="exampleModalLabel">
// //                 Edit Product Type
// //               </h1>
// //               <button
// //                 type="button"
// //                 className="btn-close"
// //                 data-bs-dismiss="modal"
// //                 aria-label="Close"
// //                 id="closeModalBtnEdit"
// //               ></button>
// //             </div>
// //             <div className="modal-body">
// //               <form onSubmit={handleFormSubmit}>
// //                 <div className="d-flex align-items-center flex-column mb-4 product-type-form-group">
// //                   <input
// //                     type="text"
// //                     id="prodType"
// //                     name="prodType"
// //                     value={editProductType.prodType}
// //                     onChange={handleInputChange}
// //                     autoComplete="off"
// //                     className="px-2 rounded-0"
// //                     placeholder="Your Product Type"
// //                   />
// //                 </div>
// //                 <div className="d-flex flex-column mb-4 product-type-form-group">
// //                   <label htmlFor="isActive">Is Active</label>
// //                   <input
// //                     type="checkbox"
// //                     id="isActive"
// //                     name="isActive"
// //                     checked={editProductType.isActive}
// //                     onChange={handleInputChange}
// //                     autoComplete="off"
// //                     className="px-2 rounded-0"
// //                     style={{ width: "24px" }}
// //                   />
// //                 </div>
// //                 <button
// //                   type="submit"
// //                   className="text-capitalize product-type-form-button"
// //                 >
// //                   Update
// //                 </button>
// //               </form>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// // export default ProductType;

// // // import React, { useEffect, useState } from "react";
// // // import { Link } from "react-router-dom";
// // // import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";

// // // const ProductType = () => {
// // //   const [productTypes, setProductTypes] = useState([]);
// // //   const [newProductType, setNewProductType] = useState({
// // //     prodType: "",
// // //     isActive: false,
// // //   });
// // //   const [editProductType, setEditProductType] = useState({
// // //     id: null,
// // //     prodType: "",
// // //     isActive: false,
// // //   });

// // //   useEffect(() => {
// // //     const fetchProductTypes = async () => {
// // //       try {
// // //         const response = await fetch(
// // //           `${process.env.REACT_APP_API_URL}/home/productTypeList`
// // //         );
// // //         const data = await response.json();
// // //         if (data.status === "Successful") {
// // //           setProductTypes(data.option);
// // //         } else {
// // //           console.error("Failed to fetch product types:", data.message);
// // //         }
// // //       } catch (error) {
// // //         console.error("Error fetching product types:", error);
// // //       }
// // //     };

// // //     fetchProductTypes();
// // //   }, []);

// // //   const handleEditClick = (index) => {
// // //     const selectedProductType = productTypes[index];
// // //     setEditProductType({
// // //       id: index,
// // //       prodType: selectedProductType,
// // //       isActive: true,
// // //     });
// // //   };

// // //   const handleInputChange = (e) => {
// // //     const { name, value, type, checked } = e.target;
// // //     setEditProductType((prev) => ({
// // //       ...prev,
// // //       [name]: type === "checkbox" ? checked : value,
// // //     }));
// // //   };

// // //   const handleNewProductTypeChange = (e) => {
// // //     const { name, value, type, checked } = e.target;
// // //     setNewProductType((prev) => ({
// // //       ...prev,
// // //       [name]: type === "checkbox" ? checked : value,
// // //     }));
// // //   };

// // //   const handleFormSubmit = async (e) => {
// // //     e.preventDefault();

// // //     try {
// // //       const response = await fetch(
// // //         `${process.env.REACT_APP_API_URL}/prodType/update/${editProductType.id}`,
// // //         {
// // //           method: "PUT",
// // //           headers: {
// // //             "Content-Type": "application/json",
// // //             Authorization: `Bearer ${localStorage.getItem("token")}`,
// // //           },
// // //           body: JSON.stringify({
// // //             prodType: editProductType.prodType,
// // //             isActive: editProductType.isActive ? "1" : "0",
// // //           }),
// // //         }
// // //       );

// // //       const data = await response.json();
// // //       if (data.status === "Successful") {
// // //         setProductTypes((prev) =>
// // //           prev.map((type, index) =>
// // //             index === editProductType.id ? editProductType.prodType : type
// // //           )
// // //         );
// // //         // Use Bootstrap's modal method to close the modal
// // //         const editModal = new window.bootstrap.Modal(
// // //             document.getElementById("exampleModalEdit")
// // //           );
// // //           editModal.hide();
// // //       } else {
// // //         console.error("Failed to update product type:", data.message);
// // //       }
// // //     } catch (error) {
// // //       console.error("Error updating product type:", error);
// // //     }
// // //   };

// // //   const handleNewProductTypeSubmit = async (e) => {
// // //     e.preventDefault();

// // //     try {
// // //       const response = await fetch(
// // //         `${process.env.REACT_APP_API_URL}/prodType/add`,
// // //         {
// // //           method: "POST",
// // //           headers: {
// // //             "Content-Type": "application/json",
// // //             Authorization: `Bearer ${localStorage.getItem("token")}`,
// // //           },
// // //           body: JSON.stringify({
// // //             prodType: newProductType.prodType,
// // //             isActive: newProductType.isActive ? "1" : "0",
// // //           }),
// // //         }
// // //       );

// // //       const data = await response.json();
// // //       if (data.status === "Successful") {
// // //         setProductTypes((prev) => [...prev, newProductType.prodType]);
// // //         // Use Bootstrap's modal method to close the modal
// // //         const addModal = new window.bootstrap.Modal(
// // //             document.getElementById("exampleModalAdd")
// // //           );
// // //           addModal.hide();
// // //         setNewProductType({ prodType: "", isActive: false });
// // //       } else {
// // //         console.error("Failed to add product type:", data.message);
// // //       }
// // //     } catch (error) {
// // //       console.error("Error adding product type:", error);
// // //     }
// // //   };

// // //   const handleDeleteClick = async (index) => {
// // //     const productType = productTypes[index];
// // //     const confirmDelete = window.confirm(
// // //       `Are you sure you want to delete "${productType}"?`
// // //     );

// // //     if (confirmDelete) {
// // //       try {
// // //         const response = await fetch(
// // //           `${process.env.REACT_APP_API_URL}/prodType/delete/${index}`,
// // //           {
// // //             method: "DELETE",
// // //             headers: {
// // //               Authorization: `Bearer ${localStorage.getItem("token")}`,
// // //             },
// // //           }
// // //         );

// // //         const data = await response.json();
// // //         if (data.status === "Successful") {
// // //           setProductTypes((prev) => prev.filter((_, i) => i !== index));
// // //         } else {
// // //           console.error("Failed to delete product type:", data.message);
// // //         }
// // //       } catch (error) {
// // //         console.error("Error deleting product type:", error);
// // //       }
// // //     }
// // //   };

// // //   return (
// // //     <>
// // //       <section className="product-type-page">
// // //         <div className="our-container px-3">
// // //           <div className="inner-container">
// // //             <div className="product-type-page-heading mb-4 d-flex align-items-center justify-content-between">
// // //               <div className="">
// // //                 <h2>Product Type</h2>
// // //               </div>
// // //               <div className="product-type-heading d-flex align-items-center justify-content-center">
// // //                 <button
// // //                   type="button"
// // //                   className="btn product-type-page-heading-add-link"
// // //                   data-bs-toggle="modal"
// // //                   data-bs-target="#exampleModalAdd"
// // //                 >
// // //                   <FaPlus className="plus-icon-add" />
// // //                   <span>Add</span>
// // //                 </button>
// // //               </div>
// // //             </div>
// // //             <div className="product-type-page-body table-responsive">
// // //               <table className="table">
// // //                 <thead>
// // //                   <tr>
// // //                     <th scope="col">#</th>
// // //                     <th scope="col">Product Type</th>
// // //                     <th scope="col">Actions</th>
// // //                   </tr>
// // //                 </thead>
// // //                 <tbody>
// // //                   {productTypes.length > 0 ? (
// // //                     productTypes.map((productType, index) => (
// // //                       <tr key={index}>
// // //                         <td>{index + 1}</td>
// // //                         <td>{productType}</td>
// // //                         <td>
// // //                           <button
// // //                             type="button"
// // //                             className="btn action-icon-edit-link"
// // //                             data-bs-toggle="modal"
// // //                             data-bs-target="#exampleModalEdit"
// // //                             onClick={() => handleEditClick(index)}
// // //                           >
// // //                             <FaEdit />
// // //                           </button>
// // //                           <button
// // //                             className="action-icon-delete-btn"
// // //                             onClick={() => handleDeleteClick(index)}
// // //                           >
// // //                             <FaTrash />
// // //                           </button>
// // //                         </td>
// // //                       </tr>
// // //                     ))
// // //                   ) : (
// // //                     <tr>
// // //                       <td colSpan="3">No product types available.</td>
// // //                     </tr>
// // //                   )}
// // //                 </tbody>
// // //               </table>
// // //             </div>
// // //           </div>
// // //         </div>
// // //       </section>

// // //       {/* Add Product Type Modal */}
// // //       <div
// // //         className="modal fade product-type-modal"
// // //         id="exampleModalAdd"
// // //         tabindex="-1"
// // //         aria-labelledby="exampleModalLabel"
// // //         aria-hidden="true"
// // //       >
// // //         <div className="modal-dialog modal-dialog-centered">
// // //           <div className="modal-content">
// // //             <div className="modal-header">
// // //               <h1 className="modal-title fs-5" id="exampleModalLabel">
// // //                 Add Product Type
// // //               </h1>
// // //               <button
// // //                 type="button"
// // //                 className="btn-close"
// // //                 data-bs-dismiss="modal"
// // //                 aria-label="Close"
// // //               ></button>
// // //             </div>
// // //             <div className="modal-body">
// // //               <form onSubmit={handleNewProductTypeSubmit}>
// // //                 <div className="d-flex align-items-center flex-column mb-4 product-type-form-group">
// // //                   <input
// // //                     type="text"
// // //                     id="newProdType"
// // //                     name="prodType"
// // //                     value={newProductType.prodType}
// // //                     onChange={handleNewProductTypeChange}
// // //                     autoComplete="off"
// // //                     className="px-2 rounded-0"
// // //                     placeholder="Your Product Type"
// // //                   />
// // //                 </div>
// // //                 <div className="d-flex flex-column mb-4 product-type-form-group">
// // //                   <label htmlFor="newIsActive">Is Active</label>
// // //                   <input
// // //                     type="checkbox"
// // //                     id="newIsActive"
// // //                     name="isActive"
// // //                     checked={newProductType.isActive}
// // //                     onChange={handleNewProductTypeChange}
// // //                     autoComplete="off"
// // //                     className="px-2 rounded-0"
// // //                     style={{ width: "24px" }}
// // //                   />
// // //                 </div>
// // //                 <button
// // //                   type="submit"
// // //                   className="text-capitalize product-type-form-button"
// // //                 >
// // //                   Save
// // //                 </button>
// // //               </form>
// // //             </div>
// // //           </div>
// // //         </div>
// // //       </div>

// // //       {/* Edit Product Type Modal */}
// // //       <div
// // //         className="modal fade product-type-modal"
// // //         id="exampleModalEdit"
// // //         tabindex="-1"
// // //         aria-labelledby="exampleModalLabel"
// // //         aria-hidden="true"
// // //       >
// // //         <div className="modal-dialog modal-dialog-centered">
// // //           <div className="modal-content">
// // //             <div className="modal-header">
// // //               <h1 className="modal-title fs-5" id="exampleModalLabel">
// // //                 Edit Product Type
// // //               </h1>
// // //               <button
// // //                 type="button"
// // //                 className="btn-close"
// // //                 data-bs-dismiss="modal"
// // //                 aria-label="Close"
// // //               ></button>
// // //             </div>
// // //             <div className="modal-body">
// // //               <form onSubmit={handleFormSubmit}>
// // //                 <div className="d-flex align-items-center flex-column mb-4 product-type-form-group">
// // //                   <input
// // //                     type="text"
// // //                     id="prodType"
// // //                     name="prodType"
// // //                     value={editProductType.prodType}
// // //                     onChange={handleInputChange}
// // //                     autoComplete="off"
// // //                     className="px-2 rounded-0"
// // //                     placeholder="Your Product Type"
// // //                   />
// // //                 </div>
// // //                 <div className="d-flex flex-column mb-4 product-type-form-group">
// // //                   <label htmlFor="isActive">Is Active</label>
// // //                   <input
// // //                     type="checkbox"
// // //                     id="isActive"
// // //                     name="isActive"
// // //                     checked={editProductType.isActive}
// // //                     onChange={handleInputChange}
// // //                     autoComplete="off"
// // //                     className="px-2 rounded-0"
// // //                     style={{ width: "24px" }}
// // //                   />
// // //                 </div>
// // //                 <button
// // //                   type="submit"
// // //                   className="text-capitalize product-type-form-button"
// // //                 >
// // //                   Update
// // //                 </button>
// // //               </form>
// // //             </div>
// // //           </div>
// // //         </div>
// // //       </div>
// // //     </>
// // //   );
// // // };

// // // export default ProductType;
